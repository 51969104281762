/**
* @summary Fill me
*
* @description Then i will add this.
*
* @author Ey Pacha!
*
*
* @since  1.0.0
*
* @see {@link http://dev.eypacha.com}
*
* @todo Complete documentation. Stop de usar espanglish
* 

// Usemos el modo estricto siempre que laburemos en JS > https://www.w3schools.com/js/js_strict.asp

"use strict";


/**
* @function Project
* @description Initialize project...
*/



var Project = function() {

    //**** Aca tus variables *****

    var title;

    var description;

    //***************************/

    /**
     * @function select
     * @description Easy selector helper function
     *
     */

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * @function on
     * @description Easy event listener function
     *
     */

    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
            if (all) {
                selectEl.forEach(e => e.addEventListener(type, listener))
            } else {
                selectEl.addEventListener(type, listener)
            }
        }
    }

    /**
     * @function onscroll
     * @description Easy on scroll event listener 
     *
     */

    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }


    /**
     * @function addListeners
     * @description ...
     *
     */

    var addListeners = function() {

        /*
         *  You can delete this at prod
         */
        $(document).keypress(function(event) {

            var keycode = (event.keyCode ? event.keyCode : event.which);

            switch (keycode) {

                case 109: // M
                    // 
                    break;
                case 110: // N
                    // ;
                    break;
                default:
                    break;

            }

        });

        /**
         * Navbar links active state on scroll
         */
        let navbarlinks = select('#navbar .scrollto', true)
        const navbarlinksActive = () => {
            let position = window.scrollY + 200
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return
                let section = select(navbarlink.hash)
                if (!section) return
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarlink.classList.add('active')
                } else {
                    navbarlink.classList.remove('active')
                }
            })
        }
        window.addEventListener('load', navbarlinksActive)
        onscroll(document, navbarlinksActive)

        /**
         * Scrolls to an element with header offset
         */
        const scrollto = (el) => {
            let header = select('#header')
            let offset = header.offsetHeight

            if (!header.classList.contains('header-scrolled')) {
                offset -= 20
            }

            let elementPos = select(el).offsetTop
            window.scrollTo({
                top: elementPos - offset,
                behavior: 'smooth'
            })
        }

        /**
         * Toggle .header-scrolled class to #header when page is scrolled
         */
        let selectHeader = select('#header')
        if (selectHeader) {
            const headerScrolled = () => {
                if (window.scrollY > 100) {
                    selectHeader.classList.add('header-scrolled')
                } else {
                    selectHeader.classList.remove('header-scrolled')
                }
            }
            window.addEventListener('load', headerScrolled)
            onscroll(document, headerScrolled)
        }

        /**
         * Back to top button
         */
        let backtotop = select('.back-to-top')
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add('active')
                } else {
                    backtotop.classList.remove('active')
                }
            }
            window.addEventListener('load', toggleBacktotop)
            onscroll(document, toggleBacktotop)
        }

        /**
         * Mobile nav toggle
         */

        on('click', '.mobile-nav-toggle', function(e) {

            select('#navbar').classList.toggle('navbar-mobile')
            select('#menuBars').classList.toggle('d-none')
            select('#menuClose').classList.toggle('d-none')

        })


        /**
         * Mobile nav dropdowns activate
         */
        on('click', '.navbar .dropdown > a', function(e) {
            if (select('#navbar').classList.contains('navbar-mobile')) {
                e.preventDefault()
                this.nextElementSibling.classList.toggle('dropdown-active');
                if (this.dataset.target == 'media') {
                    select('.dropdown.drop-lang ul').classList.remove('dropdown-active')
                } else {
                    select('.dropdown.drop-media ul').classList.remove('dropdown-active')
                }
            }
        }, true)

        /**
         * Scrool with ofset on links with a class name .scrollto
         */
        on('click', '.scrollto', function(e) {
            if (select(this.hash)) {
                e.preventDefault()

                let navbar = select('#navbar')
                if (navbar.classList.contains('navbar-mobile')) {
                    navbar.classList.remove('navbar-mobile')
                    select('#menuBars').classList.toggle('d-none')
                    select('#menuClose').classList.toggle('d-none')
                    select('.dropdown ul').classList.remove('dropdown-active')

                }
                scrollto(this.hash)
            }
        }, true)

        /**
         * Scroll with ofset on page load with hash links in the url
         */
        window.addEventListener('load', () => {
            if (window.location.hash) {
                if (select(window.location.hash)) {
                    scrollto(window.location.hash)
                }
            }
        });

        /**
         * Animation on scroll
         */
        window.addEventListener('load', () => {
            AOS.init({
                duration: 1000,
                easing: 'ease-in-out',
                once: true,
                mirror: false
            })
        });

        $("#gallery").unitegallery();

        select("#submit_button").addEventListener("click", () => {

            const validateEmail = (email) => {
                const isValid = String(email)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );

                return (isValid !== null)

            };

            var contactForm = select('#contact_form');
            var nameInput = select('#form_name')
            var emailInput = select('#form_email');
            var messageInput = select('#form_message');
            var submitButton = select('#submit_button');
            nameInput.classList.remove('error');
            emailInput.classList.remove('error');
            messageInput.classList.remove('error');

            submitButton.value = submitButton.dataset.sending;
            submitButton.disabled = true;

            var formInfo = select("#form_info");
            formInfo.innerHTML = "";
            formInfo.classList.remove('succes');
            formInfo.classList.remove('error');

            const name = nameInput.value.trim();
            const email = emailInput.value.trim();
            const message = messageInput.value.trim();

            var emptyInputs = false;

            var isValidMail = validateEmail(email);
            if (!isValidMail) {
                emailInput.classList.add('error');
                formInfo.classList.add('error');
                formInfo.innerHTML = formInfo.dataset.invalid;
                submitButton.value = submitButton.dataset.send;
                submitButton.disabled = false;
                return false;
            }

            if (name == '') {
                nameInput.classList.add('error');
                emptyInputs = true;
            }

            if (email == '') {
                emailInput.classList.add('error');
                emptyInputs = true;
            }
            if (message == '') {
                messageInput.classList.add('error');
                emptyInputs = true;
            }

            if (emptyInputs) {
                formInfo.innerHTML = formInfo.dataset.required;
                formInfo.classList.add('error');
                submitButton.value = submitButton.dataset.send;
                submitButton.disabled = false;
                return false;
            }

            setTimeout(() => {

                if (true) {
                    var data = {
                        "name": name,
                        "email": email,
                        "message": message,
                        "csending": true
                    };

                    $.ajax({
                        data: data,
                        // hacemos referencia al archivo mail.php
                        url: 'forms/contact.php',
                        type: 'post',
                        beforeSend: () => {
                            console.log('📦 Sending message...', data)
                        },
                        success: (response) => {
                            contactForm.reset();
                            submitButton.value = submitButton.dataset.send;
                            submitButton.disabled = false;

                            if (response.indexOf('250 OK id=') == -1) {
                                formInfo.innerHTML = formInfo.dataset.error;
                                formInfo.classList.add('error');
                                console.log(response, '❌ ERROR');
                            } else {
                                formInfo.innerHTML = formInfo.dataset.success;
                                formInfo.classList.add('success');
                                console.log('✅ SUCCESS');
                            }
                        }
                    });
                }
            }, 500);
        })
        entryPoint();
    }

    /**
     * @function entryPoint
     * @description Punto de entrada. 
     *
     */

    var entryPoint = () => {

        // More stuff

    }


    return {

        init: function() {

            addListeners();

        }

    }

}();

$(document).ready(function() { Project.init(); });